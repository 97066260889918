import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

const Coolfood = props => {
  return (
    <div className="block">
      <div className="blocka">
        <Link to="/project/coolfood"><img src='img/coolfood-1.png' style={{width: '100%', height: '100%'}}/></Link>
        <div className="block-hover">
          <div className="block-title">Coolfood</div>
          <div className="block-category">Project</div>
        </div>
      </div>
    </div>
  )
}

Coolfood.propTypes = {}

export default Coolfood
