import React from 'react';
import PropTypes from 'prop-types';

const Arkeogis = props => {
  return (
    <div className="page page-project">
      <h1>Arkeogis</h1>
      <p>ArkeoGIS permet de mettre en commun, de questionner et d’analyser, au moyen d’une interface cartographique, les données scientifiques géo-référencées concernant le passé (archéologie, environnement, histoire, …). C'est une plateforme sécurisée en ligne, accessible en quatre langues (Allemand, Anglais, Espagnol et Français).</p>
      <p>&nbsp;</p>
      <div className="hcenter">
        <video controls width='80%'>
          <source src="https://arkeogis.org/wp-content/uploads/2020/11/arkeogis_demo.mp4" type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <p>&nbsp;</p>
      <div className="hcenter">
        <img width='80%' alt="Arkeogis - Présentation des données" src="https://arkeogis.org/wp-content/uploads/2020/11/lizzie-scholtus-pres2.jpg"/>
      </div>
      <p>
        Le site : <a href="https://arkeogis.org/" target="_blank">https://arkeogis.org/</a>
      </p>
    </div>
  );
};

Arkeogis.propTypes = {

};

export default Arkeogis;
