import React from 'react';
import { Router, Link } from "react-router-dom"
import PropTypes from 'prop-types';

const Header = props => {
  return (
    <header className="App-header">
    <Link className="App-header-link-Home" to="/">Nicolas Dimitrijevic</Link>
    <Link className="App-header-link-cv" to="/cv">CV</Link>
    </header>
  );
};

Header.propTypes = {

};

export default Header;
