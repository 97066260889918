import React from 'react';
import PropTypes from 'prop-types';

const Airconmote = props => {
  return (
    <div className="page page-project">
      <h1>Airconmote</h1>
      <p>
        Airconmote est un projet à la fois informatique et électronique, à base de micro-contrôleurs. Il permet de rendre connectés certains climatiseurs, avec une interface moderne (reactjs) pour ordinateur ou smartphone. Il est compatible avec les protocoles standards d'objets connectés. Le serveur est en C++.
      </p>
      <div className="hcenter">
        <img width='80%' alt="Airconmote - Interface principale" src="/img/airconmote-1.png"/>
      </div>
    </div>
  );
};

Airconmote.propTypes = {

};

export default Airconmote;
