import React from 'react'
import PropTypes from 'prop-types'
import { Routes, Route } from "react-router-dom"

import About from '../pages/About'
import Home from '../pages/Home'
import Arkeogis from '../pages/projects/Arkeogis'
import Coolfood from '../pages/projects/Coolfood'
import Airconmote from '../pages/projects/Airconmote'
import Electronic from '../pages/projects/Electronic'
import CV from '../pages/cv'
import Me from '../components/Me'

const Main = props => {
  return (
    <div className="main">
      <Me />
      <Routes>
        <Route path="/about" element={<About />}/>
        <Route path="/project/arkeogis" element={<Arkeogis />}/>
        <Route path="/project/coolfood" element={<Coolfood />}/>
        <Route path="/project/airconmote" element={<Airconmote />}/>
        <Route path="/project/electronic" element={<Electronic />}/>
        <Route path="/cv" element={<CV />}/>
        <Route index path="/" element={<Home />}/>
      </Routes>
    </div>
  )
}

Main.propTypes = {}

export default Main
