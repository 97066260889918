import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

const Airconmote = props => {
  return (
    <div className="block">
      <div className="blocka">
        <Link to="/project/airconmote"><img src='img/clim.jpg' style={{width: '100%', height: '100%'}}/></Link>
        <div className="block-hover">
          <div className="block-title">Airconmote</div>
          <div className="block-category">Project</div>
        </div>
      </div>
    </div>
  )
}

Airconmote.propTypes = {}

export default Airconmote
