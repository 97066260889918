import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

const Arkeogis = props => {
  return (
    <div className="block">
      <div className="blocka">
      <Link to="/project/arkeogis"><img src='img/arkeogis-1.png' style={{width: '100%', height: '100%'}}/></Link>
        <div className="block-hover">
          <div className="block-title">Arkeogis</div>
          <div className="block-category">Project</div>
        </div>
      </div>
    </div>
  )
}

Arkeogis.propTypes = {}

export default Arkeogis
