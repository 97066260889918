import React from 'react'
import PropTypes from 'prop-types'

const cv = props => {
  return (
    <div className="page page-cv">
      <img alt="CV Nicolas Dimitrijevic" width="100%" src='/img/cv-2022.png'/>
    </div>
  )
}

export default cv;
