import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Block from '../components/Block';

import Arkeogis from '../components/Arkeogis';
import Airconmote from '../components/Airconmote';
import Coolfood from '../components/Coolfood';
import Electronic from '../components/Electronic';

//import {Bounce, Fade, Flip, Hinge, JackInTheBox, Roll, Rotate, Slide, Zoom } from "react-awesome-reveal";

Home.propTypes = {

};

function Home(props) {
  const { t, i18n } = useTranslation();
  return (
    <div className="home">
      <div className="blocklist">
        <Coolfood/>
        <Arkeogis />
        <Airconmote/>
        <Electronic/>
      </div>
    </div>
  );
}

export default Home;
