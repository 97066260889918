import React from 'react';
import PropTypes from 'prop-types';

const Electronic = props => {
  return (
    <div className="page page-project">
      <h1>Électronique</h1>
      <p>
        Études et réalisations d'objets électroniques avec micro-contrôleurs et traitement de signaux analogiques.
      </p>
      <div className="hcenter">
        <img width='80%' alt="Électronique" src="/img/electronic-1.jpg"/>
      </div>
      <p>&nbsp;</p>
      <div className="hcenter">
        <img width='80%' alt="Électronique - Imprimante 3D" src="/img/imprimante-3d.jpg"/>
      </div>
    </div>
  );
};

Electronic.propTypes = {

};

export default Electronic;
