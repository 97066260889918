import React from 'react'
import PropTypes from 'prop-types'
import { Link, useMatch } from "react-router-dom"

const Me = props => {
  const match_project = useMatch('/project/:project')
  const match_cv = useMatch('/cv')
  return (
    <Link to="/cv">
      <div className={"me" + (match_project ? "hideme" : "") + (match_cv ? "hideme_on_cv" : "")}>
      </div>
    </Link>
  )
}

Me.propTypes = {}

export default Me
