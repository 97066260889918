import React from 'react';
import PropTypes from 'prop-types';

const Footer = props => {
  return (
    <footer className="App-footer">
      Copyright © 2022. All rights reserved.
    </footer>
  );
};

Footer.propTypes = {

};

export default Footer;
