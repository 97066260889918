import React from 'react'
import PropTypes from 'prop-types'

const Block = props => {
  return (
    <div className="block">{props.element}</div>
  )
}

Block.propTypes = {
  element: PropTypes.element,
}

export default Block
