import React from 'react';
import PropTypes from 'prop-types';

const Coolfood = props => {
  return (
    <div className="page page-project">
      <h1>Coolfood</h1>
      <p>COOL FOOD est un projet Franco-Britannique, créé dans le cadre du Programme de Fonds Européen de Développement Régional INTERREG FRANCE MANCHE ANGLETERRE (FMA)</p>
      <p>Il soutient la transition vers une économie sobre en carbone dans l’espace FMA et répond spécifiquement au développement et l’adoption de technologies sobres en carbone, nouvelles ou existantes, dans les secteurs ayant le plus grand potentiel de réduction des émissions de gaz à effet de serre, à savoir pour COOL FOOD : l’alimentation.</p>
      <p>&nbsp;</p>
      <div className="hcenter">
        <video controls width='80%'>
          <source src="/img/coolfood-tablette.mp4" type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
      <p>&nbsp;</p>
      <div className="hcenter">
        <img width='80%' alt="Coolfood - Suivi personalisé" src="/img/coolfood-suivi.png"/>
      </div>
      <p>
        Le site : <a href="https://coolfood.net/" target="_blank">https://coolfood.net/</a>
      </p>
    </div>
  );
};

Coolfood.propTypes = {

};

export default Coolfood;
