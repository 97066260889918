import React from 'react';
import PropTypes from 'prop-types';

const About = props => {
  return (
    <div>

    </div>
  );
};

About.propTypes = {

};

export default About;
