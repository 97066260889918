import React from 'react'
import { Link } from "react-router-dom"

const Electronic = () => {
  return (
    <div className="block">
      <div className="blocka">
        <Link to="/project/electronic"><img alt="électronique" src='img/electronic-1.jpg' style={{width: '100%', height: '100%'}}/></Link>
        <div className="block-hover">
          <div className="block-title">Électronique</div>
          <div className="block-category">Compétence</div>
        </div>
      </div>
    </div>
  )
}

export default Electronic
